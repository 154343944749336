import { IconsEnum } from '../../assets/icons/types';
import {
  FetchCategoriesSort,
  FetchCategoriesSortTypes
} from '../categories/categoriesTypes';
import {
  FetchProductsSortTypes,
  FetchProductsSort,
  FetchProductsFilters,
  FetchProductsElasticSearchFilters
} from './productsTypes';

import { words } from '../../locales/keys';

export const INITIAL_PRODUCTS_ELASTIC_SEARCH_FILTERS: FetchProductsElasticSearchFilters =
  {};

export const INITIAL_PRODUCTS_FILTERS: FetchProductsFilters = {};
export const INITIAL_PRODUCTS_SORT: FetchProductsSort = [
  FetchProductsSortTypes.CREATED_AT_DESC
];
export const INITIAL_PRODUCTS_LIMIT = 72;

export const INITIAL_PRODUCT_COLORS_FILTERS = {};
export const INITIAL_PRODUCT_COLORS_SORT: FetchCategoriesSort = [
  FetchCategoriesSortTypes.NAME_ASC
];
export const INITIAL_PRODUCT_COLORS_LIMIT = 1000;

export const INITIAL_PRODUCT_STYLES_FILTERS = {};
export const INITIAL_PRODUCT_STYLES_SORT: FetchCategoriesSort = [
  FetchCategoriesSortTypes.NAME_ASC
];
export const INITIAL_PRODUCT_STYLES_LIMIT = 1000;

export const INITIAL_PRODUCT_MATERIALS_FILTERS = {};
export const INITIAL_PRODUCT_MATERIALS_SORT: FetchCategoriesSort = [
  FetchCategoriesSortTypes.NAME_ASC
];
export const INITIAL_PRODUCT_MATERIALS_LIMIT = 1000;

export const INITIAL_PRODUCT_TYPES_FILTERS = {};
export const INITIAL_PRODUCT_TYPES_SORT: FetchCategoriesSort = [
  FetchCategoriesSortTypes.NAME_ASC
];
export const INITIAL_PRODUCT_TYPES_LIMIT = 1000;

export const INITIAL_PRODUCT_SEASONAL_FILTERS = {};
export const INITIAL_PRODUCT_SEASONAL_SORT: FetchCategoriesSort = [
  FetchCategoriesSortTypes.NAME_ASC
];
export const INITIAL_PRODUCT_SEASONAL_LIMIT = 1000;

export const INITIAL_PRODUCT_BRANDS_FILTERS = {};
export const INITIAL_PRODUCT_BRANDS_SORT: FetchCategoriesSort = [
  FetchCategoriesSortTypes.NAME_ASC
];
export const INITIAL_PRODUCT_BRANDS_LIMIT = 200;

export const INITIAL_PRODUCT_BRANDS_SELECTED_FILTERS = {};
export const INITIAL_PRODUCT_BRANDS_SELECTED_SORT: FetchCategoriesSort = [
  FetchCategoriesSortTypes.NAME_ASC
];
export const INITIAL_PRODUCT_BRANDS_SELECTED_LIMIT = 1000;
export const INITIAL_PRODUCT_BRANDS_SELECTED_PAGE = 1;

export const enum ProductRenderTypes {
  CORONA = 'Corona',
  V_RAY = 'V-Ray'
}

export const ProductRenderTypeIcons = {
  [ProductRenderTypes.CORONA]: IconsEnum.CORONA_SOLID,
  [ProductRenderTypes.V_RAY]: IconsEnum.VRAY_SOLID
};

export const productsSortI18nTextTypes = {
  [FetchProductsSortTypes.CREATED_AT_DESC]: words.sortByNewest,
  [FetchProductsSortTypes.SELECTED_PRODUCTS_COUNT_DESC]: words.sortByMostUsed,
  [FetchProductsSortTypes.USERS_CHOSEN_PRODUCTS_COUNT_DESC]:
    words.sortByMostLiked,
  [FetchProductsSortTypes.SCORE_DESC]: words.sortByFeatured
};

export enum ProductsPermissions {
  READ_CLEAR_PRODUCTS_CACHE_BUTTON = 'read_clear_products_cache_button',
  READ_CLEAR_PRODUCT_CACHE_BUTTON = 'read_clear_product_cache_button',
  READ_SELF_PRODUCTS_LIBRARY_INDEX_PAGE = 'read_self_products_library_index_page',
  READ_USER_PRODUCTS_LIBRARY_INDEX_PAGE = 'read_user_products_library_index_page',
  READ_PRODUCTS_INDEX_PAGE = 'read_products_index_page',
  READ_PRODUCT_SHOW_PAGE = 'read_product_show_page',
  READ_PRODUCT_CREATE_PAGE = 'read_product_create_page',
  READ_PRODUCT_EDIT_PAGE = 'read_product_edit_page',
  READ_PRODUCT_PREVIEW_LINK = 'read_product_preview_link',
  READ_PRODUCT_FAVORITE_BUTTON = 'read_product_favorite_button',
  READ_PRODUCT_DOWNLOAD_BUTTON = 'read_product_download_button',
  READ_SELF_COMPANY_LIBRARY_PRODUCT_DOWNLOAD_BUTTON = 'read_self_company_library_product_download_button',
  READ_OTHER_COMPANY_LIBRARY_PRODUCT_DOWNLOAD_BUTTON = 'read_other_company_library_product_download_button',
  READ_PRODUCT_PREVIEW_MODEL_BUTTON = 'read_product_preview_model_button',
  READ_PRODUCT_PREVIEW_MODEL_DOWNLOAD_BUTTON = 'read_product_preview_model_download_button',
  READ_PRODUCT_DELETE_BUTTON = 'read_product_delete_button',
  READ_PRODUCT_UPDATE_IMAGE_VERSION_BUTTON = 'read_product_update_image_version_button',
  READ_PRODUCT_AI_SEARCH_FIND_ALTERNATIVES_BUTTON = 'read_product_ai_search_find_alternatives_button',
  READ_NEW_PRODUCT_BUTTON = 'read_new_product_button',
  READ_PRODUCTS_AI_SEARCH_MODAL_BUTTON = 'read_products_ai_search_modal_button',
  READ_PRODUCTS_AI_TEXT_SEARCH_MODAL_BUTTON = 'read_products_ai_text_search_modal_button',
  READ_PRODUCTS_AI_CLIP_SEARCH_MODAL_BUTTON = 'read_products_ai_clip_search_modal_button',
  READ_PRODUCTS_AI_CLIP_GLOBAL_SEARCH_MODAL_BUTTON = 'read_products_ai_clip_global_search_modal_button',
  READ_PRODUCTS_AI_CLIP_GLOBAL_SEARCH_FILTER_MODAL_BUTTON = 'read_products_ai_clip_global_search_filter_modal_button',
  READ_PRODUCTS_ELASTIC_SEARCH_MODAL_BUTTON = 'read_products_elastic_search_modal_button',
  READ_PRODUCTS_ELASTIC_SEARCH_SUGGEST_INPUT = 'read_products_elastic_search_suggest_input',
  READ_PRODUCTS_ELASTIC_SEARCH_SUGGEST_LABEL = 'read_products_elastic_search_suggest_label',
  READ_PRODUCTS_ELASTIC_CATEGORIES_NAV = 'read_products_elastic_categories_nav',
  READ_PRODUCTS_ELASTIC_CUSTOM_TAGS_FILTER = 'read_products_elastic_custom_tags_filter',
  READ_PRODUCTS_CLIENTS_FILTER = 'read_product_clients_filter',
  READ_PRODUCTS_FAVORITE_CHECKBOX_FILTER = 'read_products_favorite_checkbox_filter',
  READ_PRODUCTS_FAVORITE_BUTTON_FILTER = 'read_products_favorite_button_filter',
  READ_PRODUCTS_NDA_FILTER = 'read_products_nda_filter',
  READ_PRODUCTS_MY_LIBRARY_FILTER = 'read_products_my_library_filter',
  READ_PRODUCTS_BLOCKED_FILTER = 'read_products_blocked_filter',
  READ_PRODUCTS_COLORS_FILTER = 'read_products_colors_filter',
  READ_PRODUCTS_MATERIALS_FILTER = 'read_products_materials_filter',
  READ_PRODUCTS_TYPE_FILTER = 'read_products_type_filter',
  READ_PRODUCTS_SEASONAL_FILTER = 'read_products_seasonal_filter',
  READ_PRODUCTS_TOTAL_COUNT = 'read_products_total_count',
  READ_PRODUCTS_ELASTIC_SEARCH_META = 'read_products_elastic_search_meta',
  READ_PRODUCTS_AI_SEARCH_DETECTED_ITEM_TITLE = 'read_products_ai_search_detected_item_title',
  READ_PRODUCTS_LOAD_MORE_BUTTON_ITEMS_COUNT = 'read_products_load_more_button_items_count',
  READ_PRODUCT_COPY_LINK_BUTTON = 'read_product_copy_link_button',
  READ_PRODUCT_EDIT_BUTTON = 'read_product_edit_button',
  READ_PRODUCT_BLOCK_BUTTON = 'read_product_block_button',
  READ_PRODUCT_INDEX_PRODUCT_CUSTOM_TAGS_BUTTON = 'read_product_index_product_custom_tags_button',
  READ_PRODUCT_SUBMIT_TO_TASK_OR_PROJECT_BUTTON = 'read_product_submit_to_task_or_project_button',
  READ_PRODUCT_RENDER_TYPES = 'read_product_render_types',
  READ_PRODUCT_SKU = 'read_product_sku',
  READ_PRODUCT_USED = 'read_product_used',
  READ_PRODUCT_CLIENTS = 'read_product_clients',
  READ_PRODUCT_CATEGORY = 'read_product_category',
  READ_PRODUCT_STYLE = 'read_product_style',
  READ_PRODUCT_COLLECTIONS = 'read_product_collections',
  READ_PRODUCT_MATERIALS = 'read_product_materials',
  READ_PRODUCT_COLORS = 'read_product_colors',
  READ_PRODUCT_TAGS = 'read_product_tags',
  READ_PRODUCT_STATUSES = 'read_product_statuses',
  READ_PRODUCT_MISTAKE_TYPES = 'read_product_mistake_types',
  READ_PRODUCT_FILE_VERSION_TAGS = 'read_product_file_version_tags',
  READ_PRODUCT_TYPE = 'read_product_type',
  READ_PRODUCT_SEASONAL = 'read_product_seasonal',
  READ_PRODUCT_TASK = 'read_product_task',
  READ_PRODUCT_CUSTOM_TAGS = 'read_product_custom_tags',
  READ_PRODUCT_SCORE = 'read_product_score',
  READ_PRODUCT_UPLOADED = 'read_product_uploaded',
  CHANGE_PRODUCT_NAME = 'change_product_name',
  CHANGE_PRODUCT_CATEGORY_ID = 'change_product_category_id',
  CHANGE_PRODUCT_BRAND_ID = 'change_product_brand_id',
  CHANGE_PRODUCT_STYLE_ID = 'change_product_style_id',
  CHANGE_PRODUCT_MATERIAL_IDS = 'change_product_material_ids',
  CHANGE_PRODUCT_TAG_IDS = 'change_product_tag_ids',
  CHANGE_PRODUCT_MAX_FILE_IDS = 'change_product_max_file_ids',
  CHANGE_PRODUCT_PREVIEW_MODEL_ID = 'change_product_preview_model_id',
  CHANGE_PRODUCT_IMAGE_IDS = 'change_product_image_ids',
  CHANGE_PRODUCT_DEFAULT_IMAGE = 'change_product_default_image',
  CHANGE_PRODUCT_ASSOCIATED_ASSETS = 'change_product_associated_assets',
  CHANGE_PRODUCT_SKU = 'change_product_sku',
  CHANGE_PRODUCT_NDA = 'change_product_nda',
  CHANGE_PRODUCT_CLIENT_IDS = 'change_product_client_ids',
  CHANGE_PRODUCT_COLLECTION_IDS = 'change_product_collection_ids',
  CHANGE_PRODUCT_FILE_VERSION_TAG_IDS = 'change_product_file_version_tag_ids',
  CHANGE_PRODUCT_COLOR_IDS = 'change_product_color_ids',
  CHANGE_PRODUCT_STATUS_IDS = 'change_product_status_ids',
  CHANGE_PRODUCT_MISTAKE_TYPE_IDS = 'change_product_mistake_type_ids',
  CHANGE_PRODUCT_RENDER_TYPE_IDS = 'change_product_render_type_ids',
  CHANGE_PRODUCT_TYPE_ID = 'change_product_type_id',
  CHANGE_PRODUCT_SEASONAL_ID = 'change_product_seasonal_id',
  CHANGE_PRODUCT_CUSTOM_TAG_IDS = 'change_product_custom_tag_ids',
  READ_PRODUCT_ATTACH_BUTTON = 'read_product_attach_button',
  READ_PRODUCT_ASSOCIATED_ASSETS = 'read_product_associated_assets',
  READ_PRODUCT_FIND_SIMILAR_BUTTON = 'read_product_find_similar_button',
  READ_PRODUCT_FIND_SIMILAR_FILTER_BUTTON = 'read_product_find_similar_filter_button',
  READ_PRODUCT_SIMILAR_PRODUCTS = 'read_product_similar_products',
  READ_PRODUCT_CACHE_SIMILAR_PRODUCTS = 'read_product_cache_similar_products',
  READ_PRODUCT_CACHE_SIMILAR_PRODUCTS_WITH_SIMILAR_PRODUCT_IMAGE_FILTER = 'read_product_cache_similar_products_with_similar_product_image_filter',
  READ_PRODUCT_SHOW_MNESIA_QUERY = 'read_product_show_mnesia_query',
  READ_PRODUCT_SHOW_ES_QUERY = 'read_product_show_es_query',
  READ_PRODUCTS_INDEX_ES_QUERY = 'read_products_index_es_query'
}

export enum ProductsCategories {
  KITCHEN = '19',
  PLANTS = '4955',
  HOME_DECOR = '21',
  EXTERIOR = '7094',
  HOME_TEXTILES = '1752',
  ARCHITECTURAL_PART = '4024',
  BATHROOM = '20',
  BEDROOMS = '18',
  CHILDRENS_ROOMS = '7533',
  COMMERCIAL_PREMISES = '8681',
  EQUIPMENT_AND_DEVICES = '1751',
  FURNITURE_FOR_STORAGE = '3',
  LIGHTING = '15',
  OFFICE_FURNITURE = '7401',
  PEOPLE_AND_ANIMALS = '6820',
  SOFAS_AND_ARMCHAIRS = '1',
  TABLES_AND_CHAIRS = '2'
}
